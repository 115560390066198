<template>
    <div :class="['wit-input']" :data-selected="internalValue" :id="id">
        <b-form-input
            v-model="internalValue"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readonly"
            :max="max"
            :min="min"
            :number="type === 'number'"
            @input="emitInput"
        >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
                ><slot :name="slot" v-bind="scope"
            /></template>
        </b-form-input>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
        id: {
            type: String,
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        max: {
            type: Number,
        },
        min: {
            type: Number,
        },
    },
    watch: {
        value() {
            this.internalValue = this.value
        },
    },
    created() {
        this.internalValue = this.value
    },
    data() {
        return {
            internalValue: null,
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.internalValue)
        },
    },
}
</script>

<style lang="scss"></style>
